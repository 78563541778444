body {
    background: #fff;
    color: #000;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 28px;
    margin: 0
}

h1 {
    font-size: 40px;
    line-height: 60px
}

h1,
h2 {
    font-weight: 700
}

h2 {
    font-size: 32px;
    line-height: 48px
}

h3 {
    font-size: 24px;
    line-height: 36px
}

h3,
h4 {
    font-weight: 700
}

h4 {
    font-size: 20px;
    line-height: 30px
}

h5 {
    font-size: 16px
}

h5,
h6 {
    line-height: 24px;
    font-weight: 700
}

h6 {
    font-size: cacl(16px)
}

a {
    text-decoration: none;
    cursor: pointer;
    color: #000
}

a:hover,
a[rel~=nofollow] {
    text-decoration: underline
}

a[rel~=nofollow] {
    color: #0452a5
}

a[rel~=nofollow]:hover {
    text-decoration: none
}

.visible {
    display: block
}

.hidden {
    display: none
}

.page {
    width: 100%
}

.container {
    position: relative;
    width: 90%;
    max-width: 1024px;
    margin: 0 auto
}

.policy-header {
    color: #000;
    padding: 16px 0;
    border-bottom: 1px solid #eee;
}

.policy-header .title {
    font-size: 24px;
    line-height: 24px;
    font-weight: 700;
    margin: 0;
    text-align: center;
}

.translations-list-container {
    color: #000;
    padding-bottom: 8px;
    margin: 0 0 16px
}

.translations-list-container .translations-list {
    margin: 0;
    padding: 0;
    list-style: none
}

.translations-list-container .translations-list .translations-list-item {
    display: inline-block;
    padding: 0;
    margin: 0 8px 8px 0;
    color: #fff
}

.translations-list-container .translations-list .translations-list-item a {
    display: inline-block;
    padding: 4px 8px
}

.translations-list-container .translations-list .translations-list-item a.active {
    color: #fff;
    background: #334055
}

.translations-content-container {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    max-width: 900px;
    margin: 3rem auto;
}

.policy-footer {
    border-top: 1px solid #eee;
}

.policy-footer-text,
.policy-footer-text a {
    color: grey;
    text-align: center;
}

.policy-footer-text a {
    text-decoration: underline;
}