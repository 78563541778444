.about-us-container {
  background-color: var(--blue-white);
  padding-bottom: 50px;

  .jumbotron {
    height: 100vh;
    background-image: url("./../../Assets/IMG/About/Founders Landscape HD.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    position: absolute;
    top: 3rem;
    left: 0;
    width: 100%;

    .header {
      width: 100%;
      position: absolute;
      top: 82%;
      left: 50%;
      margin-left: -50%;
      font-size: 60px;
      font-weight: 700;
      text-align: center;
      color: var(--whitish);
      -webkit-text-stroke: 0.6px black;
    }
  }

  .jumbotron-placeholder {
    height: 100vh;
    width: 100%;
    background-color: transparent;
  }

  .main {
    .person {
      margin: 20px 0;

      .image {
        width: 100%;
        border-radius: 20px;
        margin-top: 27px;
        margin-bottom: 35px;
      }

      .name {
        font-weight: 600;
        color: var(--blue-transparent);
        font-size: 25px;
      }

      .description {
        color: var(--blue-transparent);
        font-size: 16px;
        line-height: 30px;
        margin-top: 27px;
      }
    }
  }

  @media screen and (max-width: 850px) {
    .jumbotron {
      .header {
        font-size: 45px;
        top: 73%;
      }
    }
  }
}