.terms-container {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  max-width: 800px;
  margin: 3rem auto;
}

.terms-heading {
  font-size: 23px;
  font-weight: bold;
  margin-bottom: 10px;
}

.terms-subheading {
  font-size: 23px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
}

.terms-text {
  font-size: 16px;
  margin-bottom: 10px;
  color: grey;
}

.terms-link {
  color: #007bff;
  text-decoration: none;
}

.terms-link:hover {
  text-decoration: underline;
}

.terms-list {
  padding-left: 20px;
}

.terms-list-item {
  font-size: 16px;
  margin-bottom: 5px;
  cursor: pointer;
  color: blue;
  max-width: fit-content;
}

.terms-copyright {
  text-align: center;
  color: gray;
}