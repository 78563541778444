.jumbo-container {
  .jumbo {
    height: 500px;

    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;

    .left {
      .header {
        font-size: 40px;
      }

      .blue {
        color: var(--blue-transparent);
      }

      .orange {
        color: var(--orange-primary);
      }

      .small {
        font-size: 16px;
      }

      .download {
        background-color: var(--blue-primary);
        border-radius: 50px;
        height: 45px;
        width: 185px;
      }
    }

    .right {
      background-color: var(--light-purple);
      width: -webkit-fill-available;
      max-width: 550px;
      height: 350px;
      border-radius: 20px;

      img {
        height: 450px;
      }
    }
  }

  & {
    @media screen and (max-width: 1000px) {
      .left {
        .header {
          font-size: 30px;
        }
      }

      .right {
        width: 400px;

        .img {
          height: 380px;
        }
      }
    }

    @media screen and (max-width: 850px) {
      .right {
        width: 50%;

        .img {
          height: 300px;
        }
      }
    }

    @media screen and (max-width: 700px) {
      .jumbo {
        flex-direction: column-reverse !important;
        margin-top: 250px;

        .left {
          .header {
            margin-top: 20px;
            font-size: 30px;
          }
        }

        .right {
          img {
            width: 300px;
            height: unset;
          }
        }
      }
    }

    @media screen and (max-width: 500px) {
      .jumbo {
        align-items: center;

        .left {
          .header {
            font-size: 30px;
          }
        }

        .right {
          width: 90%;
        }
      }
    }

    @media screen and (max-width: 450px) {
      .jumbo {
        margin-top: 300px;
      }
    }

    @media screen and (max-width: 400px) {
      .jumbo {
        margin-top: 160px;
        align-items: center;

        .left {
          .header {
            font-size: 7vw;
          }
        }

        .right {
          height: 250px;

          img {
            width: 200px;
          }
        }
      }
    }
  }
}