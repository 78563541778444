.disclaimer-container {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    max-width: 800px;
    margin: 3rem auto;
  }
  
  .disclaimer-heading {
    font-size: 27px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .disclaimer-subheading {
    font-size: 27px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .disclaimer-text {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .disclaimer-link {
    color: #007bff;
    text-decoration: none;
  }
  
  .disclaimer-link:hover {
    text-decoration: underline;
  }
  
  .disclaimer-list {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .disclaimer-list-item {
    font-size: 16px;
    margin-bottom: 5px;
  }
  .disclaimer-copyright{
    text-align: center;
    color: gray;
  }