.cards-container {
  padding-top: 50px;
  padding-bottom: 100px;

  .card {
    border-radius: 20px;
    margin: 10px 0;

    .header-row {
      height: 50px;
      align-items: center;
    }
    .header {
      font-size: 28px;
      font-weight: 600;
      color: var(--blue-transparent);
      text-align: left;
      width: 100%;
      margin-bottom: 20px;
    }
    .content {
      .text {
        color: var(--blue-transparent);
        line-height: 33px;
        font-size: 17px;
      }
      .image {
        margin-left: 70px;
        height: 200px;
      }
    }
    .suffix-image {
      height: 150px;
    }
  }
  .card-large {
    height: fit-content;
    min-height: 400px;
    width: 100%;
    padding: 0 20px;
    padding-top: 40px;
    .content {
      .text {
        width: 60%;
      }
    }
  }
  .cards-row {
    .card-small {
      height: 600px;
      width: 50%;
      padding: 0 20px;
      padding-top: 40px;

      .header-row {
        height: fit-content;
        padding-top: 80px;
        .suffix-image {
          margin-top: -100px;
        }
      }
      .header {
        margin: 10px 0 !important;
      }
      .content {
        .text {
          width: 100%;
        }
      }
      .image {
        height: 100px;
        width: 100px;
        margin: 0 !important;
      }
    }
  }
  .card-blue {
    background-color: var(--light-purple);
  }
  .card-orange {
    background-color: var(--orange-primary-transparent);
  }

  @media screen and (max-width: 800px) {
    margin-top: 50px;
    .suffix-image {
      display: none !important;
    }

    .card {
      padding-top: 240px;
      padding-bottom: 25px;
      .header {
        font-size: 24px;
      }
      .content {
        padding-top: 30px;
        .image {
          position: absolute;
          margin-top: -270px;
          margin-left: -5px;
          height: 170px;
          width: 170px;
        }
      }
    }
    .card-large {
      .content {
        .text {
          width: 100%;
        }
      }
    }
    .cards-row {
      .card-small {
        height: 700px;
        .header-row {
          padding-top: 25px;
        }
        .content {
          .text {
            width: 100%;
          }
        }
      }
    }
  }
  @media screen and (max-width: 740px) {
    .cards-row {
      flex-direction: column;
      .card-small {
        width: 100%;
        height: 600px;
        padding-top: 200px !important;
        justify-content: center;

        .image {
          position: absolute !important;
          margin-top: -200px !important;
          margin-left: -5px !important;
          height: 140px !important;
          width: 140px !important;
        }
      }
    }
  }
  @media screen and (max-width: 740px) {
    .header {
      display: none;
    }
    .small-header {
      display: block !important;
    }
  }
  @media screen and (max-width: 500px) {
    .card-large,
    .card-small {
      .image {
        height: 140px !important;
        width: 140px !important;
      }
      height: fit-content !important;
      padding-bottom: 40px !important;
      .content {
        margin-top: 10px !important;
        padding-top: 20px !important;
        padding-bottom: 20px !important;
      }
    }
  }
}
.small-header {
  display: none;
}
