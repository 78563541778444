.footer-container {
  background-color: var(--blue);
  padding-bottom: 1rem;

  .logo {
    height: 80px;
  }

  .footer {
    border-top: 1px solid lightsteelblue;

    .left {
      width: 60%;

      .header {
        color: var(--whitish);
        font-size: 25px;
        margin-bottom: 30px;
      }

      .item {
        margin-bottom: 30px;

        .icon {
          height: 24px;
          width: 24px;
          margin-right: 20px;
        }

        .label {
          color: var(--whitish);
          font-size: 13.5px;
        }
      }

      .social {
        margin-top: 30px;

        .social-icon {
          margin-right: 30px;
          height: 22px;
          width: 22px;

          &:last-child {
            margin: none;
          }
        }
      }
    }

    .right {
      .col {
        .col-header {
          font-size: 18px;
          font-weight: 500;
          color: var(--light-purple);
          margin-bottom: 25px;
        }

        align-items: flex-end;

        .col-items {
          width: 130px;

          .footer-link {
            font-size: 14.5px;
            margin-bottom: 20px;
            color: var(--light-purple);
          }
        }
      }
    }
  }

  .bottom {

    .copy,
    .label,
    .divider {
      color: var(--whitish);
      font-size: 14px;
      font-weight: 400;
    }

    .row {
      .divider {
        margin: 0px 5px;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .bottom {
      margin-top: 20px;
      padding-bottom: 150px;

      .copy {
        margin-bottom: -100px;

        position: absolute;
      }
    }
  }

  @media screen and (max-width: 500px) {
    .footer {
      flex-direction: column;

      .left {
        .header {
          font-size: 20px;
        }

        width: 100%;
      }

      .right {
        justify-content: flex-start;

        .col {
          .col-items {
            margin-top: 30px;
            width: 100%;
          }
        }
      }
    }
  }

  @media screen and (max-width: 430px) {
    .bottom {
      a:last-child {
        position: absolute;
        margin-top: 60px;
      }

      .copy {
        margin-bottom: -140px;
      }
    }
  }
}