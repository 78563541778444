.chat-container {
  background-color: var(--blue-white);
  min-height: 100vh;

  .left {
    position: fixed;
    z-index: 99999999;
    background-color: var(--light-purple);
    min-height: 100vh;
    width: 300px;
    .top {
      width: 100%;
      height: 120px;
      background-color: var(--blue-white);
      .logo {
        width: 160px;
      }
    }
    .button {
      background-color: var(--blue-primary);
      border-radius: 50px;
      color: var(--light-purple);
    }
    .download {
      height: 43px;
      width: 180px;
      position: absolute;
      bottom: 40px;
      left: 60px;
    }
    .new {
      width: 150px;
      height: 45px;
      margin-top: 40px;
    }

    .chat {
      width: 100%;

      padding: 20px;
      .header {
        color: var(--blue-transparent-2);
        font-weight: 600;
        font-size: 15px;
      }
      .items {
        margin-top: 10px;
        .item {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 7px;
          .icon {
            height: 18.5px;
          }
          .label {
            color: var(--blue);
            font-size: 14.5px;
          }
        }
      }
    }
  }
  .new-chat-container {
    .top {
      min-height: 400px;
      padding-top: 120px;
      .image {
        height: 200px;
        width: 200px;
        border-radius: 50%;
      }
      .header {
        color: var(--blue-primary);
        font-weight: 600;
        font-size: 38px;
        text-align: center;
      }
    }
    .bottom {
      min-height: 350px;

      .prompts {
        margin-top: 50px;
        .prompt {
          border: 1px solid var(--blue-transparent-3);

          height: 100px;
          justify-content: center;
          padding-left: 20px;
          border-radius: 14px;

          .title {
            color: var(--blue-transparent-2);
            font-weight: 600;
            font-size: 15.5px;
          }
          .tag {
            font-weight: 400;
            color: var(--blue-transparent-2);
            font-size: 13px;
          }
        }
      }
    }
  }
  .chat-window {
    padding: 30px 0;
    margin-top: 90px;
    margin-left: 300px;
    .received-container {
      margin: 10px 0;
      .avatar {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        margin-left: -60px;
      }
      .message {
        background-color: var(--light-purple);
        width: 100%;

        border-radius: 13px;
        box-sizing: border-box;
        padding: 10px 15px;
        .content {
          font-size: 15px;
          color: var(--blue-transparent);
          font-weight: 500;
        }
        .actions-container {
          .actions {
            width: 60px;
            .action {
              color: var(--blue-transparent-3);
              cursor: pointer;
            }
            .dislike {
              -webkit-transform: scaleX(-1);
              transform: scaleX(-1);
            }
          }
        }
      }
    }
    .sent-container {
      margin: 10px 0;
      .message {
        background-color: var(--light-purple);
        width: 100%;

        border-radius: 13px;
        box-sizing: border-box;
        padding: 10px 15px;
        .content {
          font-size: 15px;
          color: var(--blue-transparent);
          font-weight: 500;
        }
        .edit {
          width: 35px;
          height: 35px;
          border-radius: 50%;
        }
      }
      .avatar {
        margin-right: -60px;
        margin-top: 10px;
      }
    }
    .regenerate-row {
      margin: 50px 0;
      .regenerate {
        width: 220px;
        border-radius: 50px;
        background-color: var(--blue-primary);
        height: 42px;
        .icon {
          transform: rotate(180deg);
        }
        .label {
          font-size: 13px;
        }
      }
    }
  }
  .send-row {
    width: 100%;
    height: 45px;
    margin-top: 120px;

    .input {
      font-family: "Open Sans", sans-serif;

      width: 100%;
      height: 37px;
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px;
      border: 1px solid var(--blue-transparent-3);
      &::placeholder {
        color: var(--blue-transparent-3);
        font-weight: 600;
        font-family: "Open Sans", sans-serif;
      }
    }
    .input-wrapper {
      border-radius: 0px 4px 4px 0px !important;
    }
    .send-btn {
      height: 37px;
      width: 70px;
      border-radius: 0px 4px 4px 0px !important;
      background-color: var(--blue-primary);
    }
  }
  @media screen and (max-width: 1600px) {
    .chat-window {
      max-width: calc(100vw - 500px);
      margin-left: 45px;
    }
  }
  @media screen and (max-width: 1200px) {
    .new-chat-container {
      .bottom {
        .prompts {
          margin-top: -20px;
        }
        .send-row {
          margin-top: 60px;
        }
      }
    }
  }

  .toggle-sidebar-container {
    display: block;
    position: fixed;
    top: 40px;
    left: 250px;

    .toggle-sidebar {
      width: 35px;
      height: 35px;
    }
  }
  @media screen and (max-width: 900px) {
    .left {
      position: fixed;
    }
    .new-chat-container {
      .bottom {
        .prompts {
          .prompt {
            width: 300px;
          }
        }
      }
    }
    .chat-window {
      width: calc(100% - 100px);
      max-width: unset;
    }
  }
}
