.nav-container {
  background-color: var(--blue-white);
  height: 90px;
  position: fixed;
  z-index: 99999999;
  top: 0;
  left: 0;
  width: 100%;
  .nav {
    .logo {
      height: 60px;
    }
    .logo-placeholder {
      width: 200px;
    }

    .items {
      width: 60%;
      .link {
        font-size: 14px;
        color: var(--blue-transparent);
        font-weight: 600;
        text-decoration: none !important;
      }
      .download {
        background-color: var(--blue-primary);
        border-radius: 50px;
      }
    }
  }
  @media screen and (max-width: 950px) {
    .nav {
      .items {
        width: 70%;
        .link {
          font-size: 15px;
        }
      }
    }
  }
  @media screen and (max-width: 700px) {
    .nav {
      .items {
        display: none;
      }
      .burger {
        display: flex;
        position: absolute;
        right: 30px;
        z-index: 9999999;
      }
    }
  }
}
.burger {
  display: none;
}
@media screen and (max-width: 700px) {
  .burger {
    display: flex;
    position: fixed;
    right: 30px;
    top: 40px;
    z-index: 999999999;
  }
}
.small-nav {
  border-radius: 20px;
  height: fit-content;
  width: 280px;
  box-sizing: border-box;
  padding: 30px;
  background-color: var(--blue-white);
  position: fixed;
  right: 30px;
  z-index: 99999999;
  top: 60px;
  .close {
    background-color: var(--blue-tertiary);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
  }
  .items {
    height: 160px;
    margin-top: -20px;
    .item {
      width: fit-content;
      .icon {
        margin-right: 10px;
      }
      .label {
        color: var(--blue-tertiary);
        font-weight: 600;
      }
    }
  }
  .download {
    height: 45px !important;
    background-color: var(--blue-primary);
    border-radius: 50px !important;
    width: 180px !important;
  }
}
.nav-placeholder {
  height: 90px;
  background-color: transparent;
}
