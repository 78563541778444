.who-are-we-container {
  background-color: var(--orange-white);
  padding: 50px 0;
  .header {
    font-size: 40px;
    text-align: center;
    width: 100%;
    font-weight: 600;
    color: var(--blue-transparent);
  }
  .statements {
    margin: 40px 0px;
    .statement {
      border-radius: 15px;
      height: 350px;
      width: 48%;
      box-sizing: border-box;
      padding: 55px 20px 0px 20px;
      .row {
        .icon {
          margin-right: 20px;
          height: 60px;
          width: 60px;
        }
        .title {
          color: var(--blue-transparent-2);
          font-weight: 700;
          font-size: 25px;
        }
      }
      .description {
        color: var(--blue-transparent);
        margin-top: 30px;
        font-size: 19px;
        line-height: 35px;
      }
    }
    .statement-blue {
      background-color: var(--light-purple);
    }
    .statement-orange {
      background-color: var(--orange-primary-transparent);
    }
  }
  .core-values {
    background-color: var(--light-purple);
    border-radius: 15px;
    padding: 50px 0px;
    margin-top: 40px;
    .subheader {
      font-size: 35px;
      text-align: center;
      width: 100%;
      font-weight: 600;
      color: var(--blue-transparent);
    }
    .values {
      width: 100%;
      .value {
        display: flex !important;
        flex-direction: column !important;
        max-width: 340px;
        margin-top: 30px;

        .row {
          .icon {
            margin-right: 20px;
            height: 60px;
            width: 60px;
          }
          .title {
            color: var(--blue-transparent-2);
            font-weight: 700;
            font-size: 25px;
          }
        }
        .description {
          color: var(--blue-transparent);
          margin-top: 30px;
          font-size: 19px;
          line-height: 35px;
        }
      }
    }
  }
  @media screen and (max-width: 800px) {
    .statements {
      .statement {
        height: 380px;
      }
    }
  }
  @media screen and (max-width: 774px) {
    .statements {
      flex-direction: column;
      .statement {
        height: fit-content;
        width: 100%;
        padding: 40px 20px !important;
        &:last-child {
          margin-top: 20px;
        }
      }
    }
    .core-values {
      .values {
        .value {
          max-width: unset;
          width: 100%;
        }
      }
    }
  }
}
