.healthcare-professionals {
  background-color: var(--orange-white);
  padding: 50px 0;
  .header {
    font-size: 40px;
    text-align: center;
    width: 100%;
    font-weight: 600;
    color: var(--blue-transparent);
  }
  .main {
    padding: 30px 0;
    .left {
      width: 65%;
      .item {
        .number-container {
          background-color: var(--light-purple);
          width: 100px;
          height: 100px;
          border-radius: 8px;
          .number {
            font-size: 40px;
            font-weight: 700;
            letter-spacing: 2px;
            color: var(--blue-transparent-2);
          }
        }
        .content {
          max-width: 400px;
          margin-left: 30px;
          margin-top: 20px;
          .title {
            color: var(--blue-transparent-2);
            font-weight: 700;
            font-size: 20px;
            line-height: 28px;
          }
          .description {
            margin-top: 10px;
            color: var(--blue-transparent);
            line-height: 30px;
          }
        }
        &:last-child {
          margin-top: 80px;
        }
      }
    }
    .image {
      width: 400px;
    }
  }
  @media screen and (max-width: 1000px) {
    .main {
      .image {
        width: unset;
        height: 550px;
      }
    }
  }
  @media screen and (max-width: 850px) {
    .main {
      flex-direction: column-reverse;
      align-items: center;
      .left {
        width: 100%;
        .item {
          .content {
            width: -webkit-fill-available;
            max-width: unset;
          }
        }
      }
      .image {
        width: 350px;
      }
    }
  }
  @media screen and (max-width: 700px) {
    .main {
      .left {
        .item {
          .number-container {
            width: 80px;
            height: 80px;
            .number {
              font-size: 30px;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    .main {
      .left {
        .item {
          flex-direction: column;
          .content {
            width: 100%;
            margin-left: 0px;
          }
        }
      }
      .image {
        margin-bottom: 30px;
      }
    }
  }
  @media screen and (max-width: 500px) {
    .main {
      .image {
        width: 300px;
      }
    }
  }
}
